.container-users {
    box-shadow: 1px 1px 10px 0px var(--shadow-color);
    border-radius: 20px;
    padding: 20px;
    margin-top: 2rem;
}
/* #filter_input {
    outline: none;
    border: none;
    background: transparent;
    border-bottom: 1px solid gray;
    width: 30%;
    height: 26px;
    font-size: 16px;
    margin-bottom: 26px;
    color: #757575;
} */


#search_icon {
    font-size: 24px;
    cursor: pointer;
}

table {
    width: 100%;
}


th {
    text-align: left;
    padding: 16px;
    color: var(--primary);
}

.tbl_row {
    margin-bottom: 5px;
}

td {
    padding: 10px 0px;
    padding-left: 16px;
    font-size: 16px;
    color: var(--secondary);
}



.delete_icon {
    color: red;
}

.edit_icon {
    color: rgb(32, 92, 173);
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: var(--background-tint) !important;
}

.profile {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.user_status {
    border: 1px solid var(--primary);
    padding: 6px 21px;
    border-radius: 20px;
    color: var(--primary);
}


@media only screen and (max-width : 600px) {
    .user_status {
        font-size: 12px;
        border: 1px solid var(--primary);
        padding: 6px 9px;
        border-radius: 20px;
        color: var(--primary);
    }

    td {
        padding: 10px 0px;
        padding-left: 16px;
        font-size: 12px;
        color: var(--secondary);
    }

    th {
        font-size: 14px;
    }
}