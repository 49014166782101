.btn{
    padding: 0.7em 1.3em;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: var(---primary);
    transition: all 1000ms;
    font-size: 15px;
    position: relative;
    overflow: hidden;
    outline: 2px solid var(---primary);
}
.btn:hover {
    color: #ffffff ;
    transform: scale(1.1);
    outline: 2px solid var(--background-tint);
    box-shadow: 4px 5px 17px -4px var(--background-tint);
    /* background-color: white ;
    color: var(--primary);
    border: 1px solid var(--primary); */

}

.btn::before {
    content: "";
    position: absolute;
    left: -50px;
    top: 0;
    width: 0;
    height: 100%;
    background-color: var(---primary);
    transform: skewX(45deg);
    z-index: -1;
    transition: width 1000ms;
}
  
.btn:hover::before {
    width: 200%;
}

  
.btn-primary {
    background-color: var(--background-tint) ;
    color: var(--primary);
    border: 1px solid var(--primary);
}

.btn-primary:hover {
    color: #ffffff;
    background-color: var(--primary);
    border-color: var(---primary);
}

@media only screen and (max-width : 600px){
    .btn-primary{
        padding: 0.5em 1em;
    }
}