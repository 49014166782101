.min_alert  {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 2rem;
    z-index: 10;
    width: 50%;
}

@media screen and (min-width : 200px) and (max-width : 600px){
    .min_alert  {
        /* display: flex;
        justify-content: center;
        position: sticky;
        top: 2rem;
        z-index: 10; */
        justify-content: flex-start;
        width: 100%;
    }
}