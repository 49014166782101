.container-center {
    width: 50%;
    margin: 2rem auto;
}

.heading {
    text-align: center;
    font-weight: 600;
    padding: 0px 18px;
}

#form_container {
    margin-top: 2rem;
    padding: 24px;
    box-shadow: 1px 1px 10px 0px var(--shadow-color);
    border-radius: 20px;
}

.form {
    display: flex;
    flex-direction: column;
    margin: 16px 0px;
}

.form-group {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6px 0px;
}

.form_heading {
    font-weight: 400;
    font-size: 21px;
}

label {
    font-size: 18px;
    font-weight: 200;
}

.txtbox {
    font-size: 18px;
    width: 60%;
    height: 3rem;
    padding: 2px 9px;
    border-radius: 10px;
    outline: none;
    border: 1px solid var(--primary);
}

.btn_wrapper {
    margin: 6px 0px;
}

.btn_wrapper> :first-child {
    float: right;
}

.eye {
    position: absolute;
    right: 0px;
    color: var(--primary);
    padding: 10px;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .container-center {
        width: 100%;
    }

    label {
        width: 100%;
        font-size: 18px;
        font-weight: 200;
    }

    .eye {
        top: 46%;
        color: var(--primary);
        padding: 10px;
        cursor: pointer;
    }

    .form-group {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        margin: 3px 0px;
    }

    .txtbox {
        font-size: 18px;
        width: 100%;
        height: 3rem;
        padding: 2px 9px;
        border-radius: 10px;
        outline: none;
        border: 1px solid var(--primary);
    }

    .btn_wrapper {
        margin: 14px 0px;
    }


}

@media only screen and (min-width : 600px) and (max-width : 1500px) {
    .container-center {
        width: 87%;
    }
}

@media only screen and (max-width: 412px) {
    .btn_wrapper {
        margin: 14px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}