.forgetbtncontainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

#forgetbtn {
    margin: 0px;
    cursor: pointer;
    font-size: 15px;
    background: #01d28e;
    border: 1px solid #01d28e;
    color: #fff;
    padding: 10px 20px;
    width: 50%;
    border-radius: 13px;
}

#forgetbtn:hover {
    background: #6cf0c2;
}
