.main-container {
    display: flex;
}

main {
    width: 100%;
    padding: 20px;
}

.sidebar {
    color: var(--secondary);
    height: 98vh;
    width: 275px;
    box-shadow: 1px 1px 10px 0px var(--shadow-color);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: sticky;
    left: 0px;
    top: 10px;
}

.mobilesidebar {
    display: none;
}

.logo_wrapper {
    text-align: center;
    padding: 12px 6px;
    border-bottom: 2px solid var(--dark-gray);
    border-top-right-radius: 20px;
}


.logo {
    width: 7rem;
}

.links {
    margin-top: 16px;
}

.link {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--dark-gray);
    color: var(--secondary);
    padding: 10px 15px;
    gap: 14px;
    box-shadow: 1px 1px 5px var(--shadow-color);
    border-radius: 5px;
    margin: 10px 5px;
    transition: all 0.5s;
}

.link:hover,
.active {
    background-color: var(--background-tint);
    ;
    color: var(--primary);
    text-decoration: none;
}

a {
    text-decoration: none;
}

.icon {
    display: flex;
    align-items: flex-end;
    font-size: 24px;
}

.link_text {
    font-size: 16px;
    color: var(--secondary);
}

#storageMeter {
    width: 14rem;
    height: 16px;
    margin: 2px 6px;
}

.bottom_section {
    margin-bottom: 20px;
}

#lblStorage {
    margin-left: 8px;
}

#storage {
    color: var(--primary);
    font-size: 12px;
    text-align: end;
    margin-right: 10px;
    font-weight: bold;
}

#version {
    text-align: center;
}

.toggleicon {
    display: none;
}

@media only screen and (max-width: 800px) {
    .sidebar {
        display: none;
        color: var(--secondary);
        height: 98vh;
        width: 275px;
        box-shadow: 1px 1px 10px 0px var(--shadow-color);
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        margin: 10px auto;
        position: sticky;
        left: 0px;
        top: 10px;
    }

    .mobilesidebar {
        display: block;
        position: absolute;
        /* top: 10px; */
        left: 0px;
        background-color: white;
        z-index: 2;
        cursor: pointer;
        width: 57%;
        box-shadow: 1px 1px 10px 0px var(--shadow-color);
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        height: 100vh;
    }

    .toggleicon {
        display: block !important;
    }

    .crossicon {
        display: block !important;
        width: 50%;
        position: absolute;
        right: 13px;
        top: 18px;
        z-index: 50;
    }

    .link {
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--dark-gray);
        color: var(--secondary);
        padding: 10px 15px;
        gap: 14px;
        box-shadow: 1px 1px 5px var(--shadow-color);
        border-radius: 5px;
        margin: 10px 5px;
        transition: all 0.5s;
        width: 79%;
    }

    #version {
        text-align: center;
        width: 100%;
    }

    #storage {
        width: 62%;
        color: var(--primary);
        font-size: 12px;
        text-align: end;
        margin-right: 10px;
        font-weight: bold;
    }

    .logo_wrapper {
        width: 100%;
        text-align: center;
        padding: 12px 6px;
        border-bottom: 2px solid var(--dark-gray);
        border-top-right-radius: 20px;
    }

    .bottom_section {
        position: absolute;
        bottom: 0px;
        margin-bottom: 28px;
    }
}

@media only screen and (max-width :420px) {
    .mobilesidebar {
        display: block;
        position: absolute;
        /* top: 10px; */
        left: 0px;
        background-color: white;
        z-index: 2;
        cursor: pointer;
        width: 76%;
        height: 100vh;
    }

    .crossicon {
        display: block !important;
        width: 43%;
        position: absolute;
        right: -23px;
        top: 26px;
        z-index: 50;
    }
}