* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.heading {
  padding: 18px;
  font-size: 32px;
}

.container-fluit {
  margin-top: 1rem;
}

:root {
  --primary: rgb(0, 77, 37);
  --background-tint: rgba(0, 77, 37, 0.1);
  --secondary: rgb(117, 117, 117);
  --dark-gray: rgb(114, 114, 114, 0.5);
  --shadow-color: rgba(0, 0, 0, 0.2);
}

.action_icons {
  font-size: 24px;
  cursor: pointer;
  padding: 0% 1%;
  /* border-radius: 30%; */
}

/* .action_icons:hover {
  background-color: var(--background-tint);
} */

.dropdown {
  max-width: 11rem;
  padding: 0% 10px;
  margin-bottom: 1rem;
}

/* option:hover {
  border-color: var(--primary);
} */

.custom-select{
  background-color: var(--background-tint);
}

/* .custom-select:focus{
  border-color: var(--primary);
} */