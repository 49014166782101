.dashboard_heading {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 2rem;
}

.card_section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.card {
    border-radius: 20px;
    box-shadow: 1px 1px 10px 0px var(--shadow-color);
    padding: 18px;
    width: 350px;
    height: 150px;
    margin-bottom: 10px;
}

.row {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    height: 100%;
}

.card_title {
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;
    color: var(--secondary);
}

.col-md-6 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.total {
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    font-size: 32px;
    color: var(--primary);
}

.card_icon {
    color: var(--background-tint);
    width: 120px;
    height: 120px;
}

.graph_section {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 1rem 0px;
    gap: 15px;
}

.chart {
    width: 48%;
    border-radius: 20px;
    box-shadow: 1px 1px 10px 0px var(--shadow-color);
    padding: 12px;
}

.container-serach {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.filter {
    margin-top: 16px;
    margin-bottom: 26px;
}


@media only screen and (max-width: 767px) and (min-width : 600px) {
    .chart {
        width: 100%;
    }

    .card_icon {
        position: absolute;
        right: 0px;
        top: -94px;
    }

    .card_section {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .card {
        border-radius: 20px;
        box-shadow: 1px 1px 10px 0px var(--shadow-color);
        padding: 18px;
        width: 48%;
        height: 150px;
        margin-bottom: 10px;
    }
}

@media only screen and (min-width :1010px) and (max-width : 1676px) {
    .card {
        width: 48%;
    }
}

@media only screen and (max-width : 600px) {
    .card_section {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }

    .card_icon {
        position: absolute;
        right: 0px;
        bottom: 0px;
    }

    .container-serach {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .card {
        width: 100%;
    }

    .dashboard_heading {
        font-size: 26px;
        text-align: center;
    }

    .graph_section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 2rem 0px;
        gap: 15px;
    }

    .chart {
        width: 100%;
        border-radius: 20px;
        box-shadow: 1px 1px 10px 0px var(--shadow-color);
        padding: 12px;
    }
}

@media only screen and (min-width : 451px) and (max-width : 600px) {
    .card {
        width: 48%;
    }
}

@media only screen and (min-width : 450px) and (max-width : 588px) {
    .col-md-6 {
        margin: -13px 0px;
    }
}

@media only screen and (min-width : 601px) and (max-width : 800px) {
    .dashboard_heading {
        font-size: 30px;
        text-align: center;
    }
}

@media screen and (max-width: 350px){
    .action_icons{
        font-size: 20px;
    }

}