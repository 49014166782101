  .content {
    width: 100%;
    display: flex;
  }

  .width1 {
    width: 50%;
    height: 100vh;
    background-image: url('https://cdn.discordapp.com/attachments/1107550253546213418/1107628602830368860/Mobile-login-Cristina.jpg');
    background-size: cover;
  }

  .width2 {
    width: 50%;
    height: 100vh;
    display: flex;
    align-items: center;
  }

  .app {
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    background-color: #f8f9fd;
  }

  .logininput {
    width: 50%;
    padding: 10px 20px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .logininput:focus {
    outline: none;
    border: 2px solid #01d28e;
  }

  .passwordbox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  input[type="submit"] {
    margin: 6px 0px;
    cursor: pointer;
    font-size: 15px;
    /* background: #01d28e; */
    background-color: #28a745;
    border: 1px solid #01d28e;
    color: #fff;
    padding: 10px 20px;
    width: 50%;
    border-radius: 13px;
  }

  input[type="submit"]:hover {
    background: #0eb97d;
    transition-duration: 600ms;
    transition-timing-function: ease-in-out;
  }

  .modal_btn {
    margin: 6px 0px;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
    padding: 10px 20px;
    width: 22%;
    border-radius: 13px;
  }

  #close_btn {
    background-color: #6c757d;
    border: 1px solid #6c757d;
  }

  #send_btn {
    background-color: #28a745;
    border: 1px solid #01d28e;
  }

  #send_btn:hover {
    background: #0eb97d;
    transition-duration: 600ms;
    transition-timing-function: ease-in-out;
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .list-container {
    display: flex;
  }

  .error {
    color: red;
    font-size: 12px;
  }

  .title {
    font-size: 25px;
    margin-bottom: 20px;
  }

  .input-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    margin: 10px;
  }

  .Text {
    text-align: center;
  }

  .forget-password {
    width: 50%;
    text-align: end;
    margin: 0px;
  }

  .form {
    display: flex;
    flex-direction: column;
    margin: 16px 0px;
    width: 100%;
  }

  .input {
    width: 50%;
    text-align: left;
    margin: 0px;
  }

  .icons {
    position: absolute;
    bottom: 8px;
    right: 26%;
  }

  #showAlert {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 2rem;
    z-index: 10;
    margin: 9px 0;
    width: 100%;

  }

  .forgetpasswordcontainer {
    width: 74%;
    text-align: end;
  }

  .errwidth {
    width: 50%;
  }

  @media only screen and (max-width: 600px) {
    .width1 {
      display: none;
    }

    .width2 {
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
    }

    .logininput {
      width: 100%;
      padding: 10px 20px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }

    .input {
      width: 100%;
      text-align: left;
      margin: 0px;
    }

    input[type="submit"] {
      margin: 6px 0px;
      cursor: pointer;
      font-size: 15px;
      background-color: #28a745;
      border: 1px solid #01d28e;
      color: #fff;
      padding: 10px 20px;
      width: 95%;
      border-radius: 13px;
    }

    .modal_btn {
      margin: 6px 0px;
      cursor: pointer;
      font-size: 15px;
      background-color: #28a745;
      border: 1px solid #01d28e;
      color: #fff;
      padding: 10px 20px;
      width: 95%;
      border-radius: 13px;
    }

    #close_btn {
      background-color: #6c757d;
      border: 1px solid #6c757d;
    }

    #send_btn {
      background-color: #28a745;
      border: 1px solid #01d28e;
    }

    #send_btn:hover {
      background: #0eb97d;
      transition-duration: 600ms;
      transition-timing-function: ease-in-out;
    }

    .forget-password {
      width: 100%;
      text-align: end;
      margin: 0px;
    }

    .icons {
      position: absolute;
      bottom: 8px;
      right: 4%;
    }

    .content {
      width: 100%;
      display: flex;
      padding: 0px 16px;
    }

    .forgetpasswordcontainer {
      width: 96%;
      text-align: end;
    }

    .errwidth {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1400px) and (min-width: 600px) {
    .width1 {
      width: 50%;
      height: 100vh;
      background-image: url('https://cdn.discordapp.com/attachments/1107550253546213418/1107628602830368860/Mobile-login-Cristina.jpg');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .logininput {
      width: 85%;
      padding: 10px 20px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 10px;
    }

    .input {
      width: 85%;
      text-align: left;
      margin: 0px;
    }

    .icons {
      position: absolute;
      bottom: 8px;
      right: 11%;
    }

    .forget-password {
      width: 85%;
      text-align: end;
      margin: 0px;
    }

    input[type="submit"] {
      margin: 6px 0px;
      cursor: pointer;
      font-size: 15px;
      background-color: #28a745;
      border: 1px solid #01d28e;
      color: #fff;
      padding: 10px 20px;
      width: 82%;
      border-radius: 13px;
    }

    .forgetpasswordcontainer {
      text-align: end;
      width: 90%;
    }

    .errwidth {
      width: 86%;
    }

  }