#userdirectory_heading {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    display: inline-block;
}

#admin_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.users {
    margin: 10px;
    font-size: 1rem;
    cursor: pointer;
    background-color: transparent;
}

.users:hover,
.selected_users {
    background-color: var(--background-tint);
}

.filter_section {
    display: flex;
}



.adminmodal_btn {
    margin: 6px 0px;
    cursor: pointer;
    font-size: 15px;
    color: #fff;
    padding: 10px 20px;
    width: 32%;
    border-radius: 13px;
}

#delete_btn {
    background-color: rgb(246, 53, 53);
    border: 1px solid rgb(246, 53, 53);
}

@media only screen and (max-width : 624px) {

    #admin_btn {
        display: flex;
        flex-direction: column;
    }

    .filter_section {
        display: flex;
        flex-wrap: wrap;
        margin: 14px 0px
    }

    .users {
        margin: 4px;
    }

    .btn {
        position: absolute;
        right: 0px;
    }

    #userdirectory_heading {
        font-size: 26px;
    }

    .res_btn {
        position: absolute;
        right: 0px;
    }

    .adminmodal_btn {
        margin: 6px 0px;
        font-size: 1rem;
        padding: 5px 10px;
        width: 50%;
    }
}