.search {
    padding: 5px 15px;
    padding-right: 38px;
    font-size: 18px;
    font-style: normal;
    border: 1px solid var(--primary);
    color: var(--primary);
    background-color: rgba(0, 77, 37, 0.1);
    outline: none;
    width: 100%;
    border-radius: 7px;
    height: 45px;
}

.search_box {
    position: relative;
    width: 270px;
    display: flex;
    align-items: center;
}

#search_text_icon {
    position: absolute;
    right: 24px;
}

.search_icon {
    font-size: 24px;
    color: var(--primary);
    position: relative;
    cursor: pointer;
}

#search_date_icon {
    position: absolute;
    right: 36px;
}

::placeholder {
    color: var(--primary);
}

.date_filter {
    display: flex;
    width: 60%;
    justify-content: flex-end;
}

#search_date_from {
    margin-right: 2%;
}

@media only screen and (max-width : 500px) {
    .search_box {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .search {
        width: 91%;
        margin-top: 12px;
    }

    /* #search_date{
        width: 100%;
    } */

    .date_filter {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    #search_date_icon {
        position: absolute;
        right: 36px;
    }
}

@media only screen and (min-width : 800px) and (max-width : 869px) {
    .search {
        margin-right: 6px;
        padding: 5px 15px;
        padding-right: 38px;
        font-size: 18px;
        font-style: normal;
        border: 1px solid var(--primary);
        color: var(--primary);
        background-color: rgba(0, 77, 37, 0.1);
        outline: none;
        width: 100%;
        border-radius: 7px;
        height: 45px;
    }

    /* 
    #search_text_icon {
        position: absolute;
        right: 36px;
    } */
}

@media only screen and (min-width : 460px) and (max-width : 626px) {
    
    .container-serach {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 15px 15px;
    }

    #search_text{
        margin-right: 12px;
    }

}

@media screen and (max-width : 900px) {
    #search_date{
        width: 91%;
    }
}

@media screen and (min-width: 460px ) and (max-width: 800px) {
    .container-serach {
        flex-direction: column;
        align-items: flex-start;
    }

    .date_filter{
        justify-content: flex-start;
        margin-top: 2%;
        width: 91%;
    }

    #search_date_from{
        margin-bottom: 1%;
    }
}