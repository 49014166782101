.pagination {
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination li {
  color: var(--primary);
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
}

.pagination__link--disabled {
  opacity: 0.4;
}

.pagination__link--active {
  background-color: var(--background-tint);
  color: white;
}

.pagination_link {
  color: var(--primary);
}

.pagination_link:hover {
  color: var(--primary);
}